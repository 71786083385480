import React, { useRef, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
const HtmlRenderer = ({ htmlContent, styleType, isList }) => {
  const sanitizeHtml = (html) => {
    return DOMPurify.sanitize(html);
  };

  const applyListStyles = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const uls = tempDiv.querySelectorAll('ul');
    const ols = tempDiv.querySelectorAll('ol');

    uls.forEach((ul) => {
      if (isList) {
        ul.classList.add('list-disc', 'pl-5', 'pr-5', 'grid', 'grid-cols-1', 'gap-2', 'md:gap-x-20', 'md:gap-y-0', 'md:grid-cols-2');
      } else {
        ul.classList.add('list-disc', 'pl-5');
      }
    });

    ols.forEach((ol) => {
      ol.classList.add('list-decimal', 'pl-5');
    });

    return tempDiv.innerHTML;
  };

  const processedHtml = applyListStyles(sanitizeHtml(htmlContent));

  const getStyleClass = () => {
    switch (styleType) {
      case 'short':
        return 'text-medium font-medium text-text-primary'; // Apply specific Tailwind classes
      case 'long':
        return 'text-large font-medium text-text-secondary w-[90%]'; // Apply different Tailwind classes
      case 'list':
        return 'text-text-highlight font-medium pr-10'; // Style for lists
      default:
        return 'text-base text-text-primary'; // Default styling
    }
  };

  return (
    <div
      className={getStyleClass()}
      dangerouslySetInnerHTML={{ __html: processedHtml }}
    />
  );
};

const CardHorizontalScrollView = ({ items, onArticleClick }) => {
  const scrollContainerRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    const updateCardWidth = () => {
      if (scrollContainerRef.current) {
        const containerWidth = scrollContainerRef.current.offsetWidth;
        const isMobile = window.innerWidth < 768;

        if (isMobile) {
          setCardWidth(containerWidth - 15);
        } else {
          setCardWidth((containerWidth / 4) - 20);
        }
      }
    };

    updateCardWidth();

    window.addEventListener('resize', updateCardWidth);
    return () => window.removeEventListener('resize', updateCardWidth);
  }, []);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -scrollContainerRef.current.offsetWidth,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: scrollContainerRef.current.offsetWidth,
      behavior: 'smooth',
    });
  };

  if(!items){
    return(
      <div>
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <div className="relative flex items-center w-[100vw] md:w-[100%] lg:w-[100%] px-[10px] gap-5 flex-row ">
      <>
        {items && items.length > (window.innerWidth < 768 ? 1 : 4) && (
          <img
            src={require('../../components/images/left_arrow.png')}
            className="cursor-pointer w-8 h-8 -pr-10 filter brightness-0 opacity-90"
            onClick={scrollLeft}
            alt="Scroll Left"
          />
        )}

        <div
          ref={scrollContainerRef}
          className="flex overflow-x-hidden gap-5 py-5 px-0 w-[100vw] overflow-hidden no-scrollbar "
        >
          {items && items.map((item, index) => {
            const maxLength = 60;
            
            const trimmedDescription = item && item.short_description && item.short_description.length > maxLength
                ?  item && item.short_description.substring(0, maxLength) + '...'
                :  item && item.short_description;

            return (
              <Link
              key={item.id} 
              to={{
                pathname: `/articles/${item.slug}`, 
                // state: item , 
              }}
            >
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg flex-none transition-transform duration-500 hover:scale-105 max-height-350"
                style={{ width: `${cardWidth}px` }}
                // onClick={() => onArticleClick(item)}
              >
                <img
                  src={`https://yogsync.com/api/${item.image}`}
                  className="w-full h-40 object-cover rounded-t-lg"
                  alt={item.title}
                />

                <div className="p-4">
                  <h2 className="text-[#D7AB0F] font-semibold text-left">{item.title}</h2>
                  {/* <p className="text-black text-sm text-left mt-2">{trimmedDescription}</p> */}
                  <HtmlRenderer htmlContent={trimmedDescription} styleType="short" />
                </div>
              </div>
              </Link>
            );
          })}
        </div>

        {items.length > (window.innerWidth < 768 ? 1 : 4) && (
          <img
            src={require('../../components/images/right_arrow.png')}
            className="cursor-pointer w-8 h-8 -pl-10 filter brightness-0 opacity-90"
            onClick={scrollRight}
            alt="Scroll Right"
          />
        )}
      </>
    </div>
  );
};

export default CardHorizontalScrollView;
