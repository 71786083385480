import React, { useRef, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
const HtmlRenderer = ({ htmlContent, styleType, isList }) => {
  const sanitizeHtml = (html) => {
      return DOMPurify.sanitize(html);
  };

  const applyListStyles = (html) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      const uls = tempDiv.querySelectorAll('ul');
      const ols = tempDiv.querySelectorAll('ol');

      uls.forEach((ul) => {
          if (isList) {
              ul.classList.add('list-disc', 'pl-5', 'pr-5', 'grid', 'grid-cols-1', 'gap-2', 'md:gap-x-20', 'md:gap-y-0', 'md:grid-cols-2');
          } else {
              ul.classList.add('list-disc', 'pl-5');
          }
      });

      ols.forEach((ol) => {
          ol.classList.add('list-decimal', 'pl-5');
      });

      return tempDiv.innerHTML;
  };

  const processedHtml = applyListStyles(sanitizeHtml(htmlContent));

  const getStyleClass = () => {
      switch (styleType) {
          case 'short':
              return 'text-medium font-medium text-text-primary'; // Apply specific Tailwind classes
          case 'long':
              return 'text-large font-medium text-text-secondary w-[90%]'; // Apply different Tailwind classes
          case 'list':
              return 'text-text-highlight font-medium pr-10'; // Style for lists
          default:
              return 'text-base text-text-primary'; // Default styling
      }
  };

  return (
      <div
          className={getStyleClass()}
          dangerouslySetInnerHTML={{ __html: processedHtml }}
      />
  );
};

const ExpertsPick = ({ items = [] }) => {
  const scrollContainerRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);

  useEffect(() => {
    const updateCardWidth = () => {
      if (scrollContainerRef.current) {
        const containerWidth = scrollContainerRef.current.offsetWidth;
        const isMobile = window.innerWidth < 768;

        if (isMobile) {
          setCardWidth(containerWidth - 15);
        } else {
          setCardWidth((containerWidth / 4) - 20);
        }
      }
    };

    updateCardWidth();

    window.addEventListener('resize', updateCardWidth);
    return () => window.removeEventListener('resize', updateCardWidth);
  }, []);

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({
      left: -scrollContainerRef.current.offsetWidth,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({
      left: scrollContainerRef.current.offsetWidth,
      behavior: 'smooth',
    });
  };

  // Ensure validItems is an array
  const validItems = Array.isArray(items) ? items : [];

  const isMobile = window.innerWidth < 768;
  const showArrows = validItems.length > (isMobile ? 1 : 4);

  return (
    <div className="bg-gradient-to-br from-yellow-50 to-yellow-100 p- pb-10 rounded-lg shadow-md relative ">
      <img
        src={require('../../components/images/Frame.png')}
        className="my-5 w-1/4 mx-auto filter brightness-0 opacity-90"
        alt="Frame"
      />
      <h1 className="text-[#D7AB0F] text-4xl font-semibold mb-8 text-center font-serif">
        Expert's Pick
      </h1>

      <div className="flex items-center">
        {showArrows && (
          <img
            src={require('../../components/images/left_arrow.png')}
            className="cursor-pointer w-8 h-8 mr-5 filter brightness-0 opacity-90"
            onClick={scrollLeft}
            alt="Scroll Left"
          />
        )}

        <div
          ref={scrollContainerRef}
          className="flex overflow-x-hidden gap-5 py-5 pb-2 items-center overflow-y-hidden no-scrollbar w-full"
        >
          {validItems.length > 0 ? (
            validItems.map((item, index) => {
              const maxLength = 60;
              const trimmedDescription =
                item.short_description && item.short_description.length > maxLength
                  ? item.short_description.substring(0, maxLength) + '...'
                  : item.short_description;

              return (
                <Link
              key={item.id} 
              to={{
                pathname: `/articles/${item.slug}`, 
                // state: item , 
              }}
            >
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg flex-none transition-transform duration-500 hover:scale-105 max-height-425"
                  style={{ width: `${cardWidth}px` }}
                >
                  <div className="relative">
                    <img
                      // src={item.image}
                      src={`https://yogsync.com/api/${item.image}`}
                      className="rounded-lg object-cover w-full h-[230px]"
                      alt={item.title}
                    />
                    <div className="absolute top-2 right-2 bg-[#f5d870] text-black p-1 rounded-full text-xs font-medium">
                      Expert-Approved
                    </div>
                  </div>
                  <div className="mt-4 px-4 pb-3">
                    <h3 className="text-[#D7AB0F] font-semibold text-xl text-left">
                      {item.title}
                    </h3>
                    {/* <p className="text-black mt-2 text-lg text-left">
                      {trimmedDescription}
                    </p> */}
                    <HtmlRenderer htmlContent={trimmedDescription} styleType="short" />
                  </div>
                </div>
                </Link>
              );
            })
          ) : (
            <p className="text-black text-center">No expert picks available</p>
          )}
        </div>

        {showArrows && (
          <img
            src={require('../../components/images/right_arrow.png')}
            className="cursor-pointer w-8 h-8 ml-5 filter brightness-0 opacity-90"
            onClick={scrollRight}
            alt="Scroll Right"
          />
        )}
      </div>
    </div>
  );
};

export default ExpertsPick;
