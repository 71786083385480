import React, { useState, useEffect } from 'react';
import LearnYogaPosesDetail from './components/LearnYogaPosesDetail';
import { useYogaPoses } from '../../api/hooks/response';
import Loader from '../../components/Loader/Loader';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';


const HtmlRenderer = ({ htmlContent, styleType }) => {
    const sanitizeHtml = (html) => {
        return DOMPurify.sanitize(html);
    };

    const processedHtml = sanitizeHtml(htmlContent);

    const getStyleClass = () => {
        switch (styleType) {
            case 'short':
                return 'text-medium font-medium text-text-primary';
            case 'long':
                return 'text-large font-medium text-text-secondary';
            default:
                return 'text-base text-text-primary';
        }
    };

    return (
        <div className={getStyleClass()} dangerouslySetInnerHTML={{ __html: processedHtml }} />
    );
};

const LearnYogaPoses = (props) => {
    const [yogaPoses, setYogaPoses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedPose, setSelectedPose] = useState(null);
    const [selectedTab, setSelectedTab] = useState('Beginner Level');
    const [filteredPoses, setFilteredPoses] = useState([]);
    const [loading, setLoading] = useState(true);
    const base_url = 'https://yogsync.com/api';
    const location = useLocation();
    const item = location.state;
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!formData.firstName) {
            newErrors.firstName = 'First Name is required';
            valid = false;
        }

        if (!formData.lastName) {
            newErrors.lastName = 'Last Name is required';
            valid = false;
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            const emailData = {
                to_name: 'Yogsync', // Customize as needed
                from_name: `${formData.firstName} ${formData.lastName}`,
                message: `Subscription from ${formData.firstName} ${formData.lastName}. Email: ${formData.email}`,
                from_email: formData.email,
            };

            emailjs.send('service_fwph7h9', 'template_8ye2mar', emailData, 'WcSa42N6XWzJw9Ld2')
                .then((response) => {
                    console.log('Email sent successfully:', response.status, response.text);
                    toast("Your message has been sent successfully!");
                    setFormData({ firstName: '', lastName: '', email: '' });
                    setErrors({});
                })
                .catch((error) => {
                    console.error('Error sending email:', error);
                    toast.error("There was an error sending your message. Please try again.");
                });
        }
    };


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${base_url}/user/posecategory/getall`);
                if (response.data.status_code === 200) {
                    const fetchedCategories = response.data.data;
                    setCategories(fetchedCategories);

                    if (item) {
                        const matchedCategory = fetchedCategories.find(
                            (category) => category.name === item.title
                        );

                        // If a match is found, set that as the selected tab, otherwise set the first category as default
                        setSelectedTab(matchedCategory ? matchedCategory.name : fetchedCategories[0].name);
                    } else {
                        setCategories(response.data.data);
                        setSelectedTab(response.data.data[0].name);
                    }

                }
            } catch (error) {
                console.error('Error fetching categories', error);
            }
        };

        fetchCategories();
    }, [base_url, item?.title]); // Add item.title as a dependency to re-run when it changes


    useEffect(() => {
        const fetchPoses = async () => {
            setLoading(true);
            try {
                const category = categories.find(cat => cat.name === selectedTab); 
                if (category) {
                    const response = await axios.get(`${base_url}/user/yogapose/getall`, { params: { category_id: category.id } });
                    if (response.data.status_code === 200) {
                        const allYogaPoses = response.data.data.flatMap(item => item.yoga_poses || []);
                        setYogaPoses(allYogaPoses);
                        setFilteredPoses(allYogaPoses); 
                    }
                }
            } catch (error) {
                console.error('Error fetching yoga poses', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPoses();
    }, [selectedTab, categories, base_url]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handlePoseClick = (pose) => {
        setSelectedPose(pose);
    };

    const handleCloseDetails = () => {
        setSelectedPose(null);
    };
    const createSlug = (title) => {
        return title
            .toLowerCase()         // Convert to lowercase
            .replace(/\s+/g, '-')  // Replace spaces with hyphens
            .replace(/[^\w\-]+/g, '') // Remove non-alphanumeric characters except hyphen
            .replace(/\-\-+/g, '-')  // Replace multiple hyphens with a single hyphen
            .trim(); // Remove leading/trailing hyphens
    };
    if (loading) return <Loader />;

    return (
        <div className="flex flex-col z-20 py-10 px-5 md:py-0 lg:px-0">
            {/* {selectedPose ? (
                <LearnYogaPosesDetail pose={selectedPose} onClose={handleCloseDetails} />
            ) : ( */}
            <>
                <div className="bg-[#fdf9e7] relative" style={{ backgroundImage: `url(${require('../../components/images/section_Header.png')})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <div className='p-5 pt-10 pb-12 pl-18 z-10'>
                        <h1 className="text-[#ffe594] text-6xl font-semibold leading-[48px] mb-0">Learn Yoga Poses</h1>
                        <h6 className="text-[#FFFEFE] text-base font-semibold text-left mt-7 mb-7 md:w-[60%] w-[90%]">
                            Build a strong foundation for your yoga practice with detailed guides on poses for everyone, whether a beginner or an expert.
                            <br /><br />
                            Take a Step Towards A Healthier You

                        </h6>
                        {/* <button className="bg-[#6D5302] text-[#EACA57] rounded-full py-3 px-5 text-base font-normal hover:bg-[#D7AB0F]">
                                Start Your 14-Day Free Trial
                            </button> */}
                    </div>
                </div>

                <div className="tab-container bg-[#fdf9e7] py-2 z-10 mt-5">
                    <div className="tab-header flex mb-6 overflow-y-scroll md:overflow-auto lg:overflow-auto">
                        {categories.map((tab) => (
                            <button
                                key={tab.id}
                                onClick={() => handleTabClick(tab.name)}
                                className={`tab-button py-2 px-3 md:px-4 lg:px-4 text-sm md:text-lg lg:text-lg font-medium ${selectedTab === tab.name ? 'text-[#D7AB0F] border-b-2 border-[#D7AB0F]' : 'text-black'}`}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="bg-[#fff] flex flex-col w-full items-center justify-center md:items-start lg:items-start px-5 md:px-10 md:mx-5 mt-10">
                    <div className="flex flex-col w-full items-center justify-center">
                        {filteredPoses.length > 0 ? (filteredPoses.map((poseData, index) => (
                            <Link key={index} className="flex mb-5 w-[100%] flex-wrap md:flex-nowrap gap-10 lg:flex-nowrap justify-center items-center border rounded-lg border-[#f9e59f] py-5 px-5 md:px-10 md:mx-5 md:justify-start lg:justify-start"
                                // onClick={() => handlePoseClick(poseData)}
                                to={{
                                    pathname: `/learn-yoga-poses/${poseData.slug}`,
                                    // state: poseData , 
                                }}
                            >
                                <img
                                    src={`${base_url}/${poseData.image}`}
                                    alt={poseData.name}
                                    className="w-[40vw] h-[20vh] md:w-[20vw] md:h-[40vh] object-cover rounded-full"
                                />
                                <div className="flex flex-col md:ml-5 gap-10">
                                    <h2 className="text-2xl md:text-5xl lg:text-4xl font-extrabold text-[#D7AB0F] text-center mt-5 md:mt-0 md:text-left lg:text-left">{poseData.name}</h2>
                                    <HtmlRenderer htmlContent={poseData.short_description} styleType="long" />
                                </div>
                            </Link>
                        ))) : (<div className='flex w-full justify-center my-20'>
                            <h1 className="text-2xl md:text-5xl lg:text-4xl font-medium  text-black text-center mt-5 md:mt-0 md:text-left lg:text-left">No data found for this Yoga pose!</h1>
                        </div>)}
                    </div>
                </div>

                <p className="text-base text-black font-semibold text-center mt-10">There's More on the Way! 🚀😊</p>
                <div className="h-[1px] bg-gray-300 my-10 mx-5 md:mx-20 lg:mx-20"></div>


                {/* Subscription Section */}
                <div className='border rounded-lg border-[#fcf2cf] z-10 py-10 px-5 md:px-10 md:mx-5 my-10 flex justify-center flex-col'>
                    <div className="flex flex-col mx-20 my-10 items-center justify-center self-center w-[90%] md:w-[50%] lg:w-[50%] gap-3">
                        <p className="text-lg text-black font-medium text-center">JOIN THE MINDFUL MOVEMENT</p>
                        <p className="text-lg text-black font-normal text-center">STAY TUNED FOR EXCITING NEWS!</p>
                        <p className="text-base text-black font-normal text-center line-clamp-2">Subscribe today and be the first to hear about unique offers, new workshops, and inspiring challenges. Together, we can grow and enhance our yoga journey.</p>
                        <p className="text-lg text-black font-medium text-center">#DISCOVERYOURFLOW</p>
                    </div>
                    <div className="flex flex-row my-10 mx-0 md:mx-20 lg:mx-20 items-center md:justify-center lg:justify-center flex-wrap md:flex-nowrap lg:flex-nowrap gap-3">
                        {/* <input placeholder="First Name" inputMode="text" className="border border-gray-300 px-10 py-5 text-black w-full" />
                            <input placeholder="Last Name" inputMode="text" className="border border-gray-300 px-10 py-5 text-black w-full" />
                            <input placeholder="Email Address" inputMode="email" className="border border-gray-300 px-10 py-5 text-black w-full" />
                            <button className="bg-[#D7AB0F] text-black rounded-full py-5 px-5 text-lg font-medium hover:bg-[#D7AB0F]">Subscribe</button> */}
                        <form onSubmit={handleSubmit} className="flex flex-row my-10 mx-0 md:mx-20 lg:mx-20 items-center md:justify-center lg:justify-center flex-wrap md:flex-nowrap lg:flex-nowrap gap-3">
                            <input
                                name="firstName"
                                placeholder="First Name"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                className="border border-gray-300 px-10 py-5 text-black w-full"
                            />
                            {errors.firstName && <span className="text-red-500 text-xs">{errors.firstName}</span>}

                            <input
                                name="lastName"
                                placeholder="Last Name"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                className="border border-gray-300 px-10 py-5 text-black w-full"
                            />
                            {errors.lastName && <span className="text-red-500 text-xs">{errors.lastName}</span>}

                            <input
                                name="email"
                                placeholder="Email Address"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="border border-gray-300 px-10 py-5 text-black w-full"
                            />
                            {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}

                            <button
                                type="submit"
                                className="bg-[#D7AB0F] text-black rounded-full py-5 px-5 text-lg font-medium hover:bg-[#D7AB0F]"
                            >
                                Subscribe
                            </button>
                        </form>
                    </div>
                </div>
            </>
            {/* )} */}
        </div>
    );
};

export default LearnYogaPoses;
