import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import HomeScreen from './screen/HomeScreen/Home';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Articles from './screen/Articles/Articles';
import LearnYogaPoses from './screen/LearnYogaPoses/LearnYogaPoses';
import VideoGallery from './screen/VideoGallery/VideoGallery';
import ContactUs from './screen/ContactUs/ContactUs';
import AboutUs from './screen/AboutUs/AboutUs';
import BecomeContributor from './components/Model/becomeContributor';
import ArticlesDetails from './screen/Articles/components/ArticlesDetails';
import LearnYogaPosesDetail from './screen/LearnYogaPoses/components/LearnYogaPosesDetail';

function App() {
  const [isContributorModalOpen, setIsContributorOpen] = useState(false);

  const openContributorModal = () => setIsContributorOpen(true);
  const closeContributorModal = () => setIsContributorOpen(false);

  const handleButtonClick = (buttonName) => {
    if (buttonName === 'BECOME A CONTRIBUTOR!') {
      openContributorModal();
    }
  };

  return (
    <Router>
      <div className="font-poppins mx-auto centered-container">
        <Header onButtonClick={handleButtonClick} />
        
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:slug" element={<ArticlesDetails />} />
          <Route path="/learn-yoga-poses/:slug" element={<LearnYogaPosesDetail />} />
          <Route path="/learn-yoga-poses" element={<LearnYogaPoses />} />
          <Route path="/video-gallery" element={<VideoGallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/articles/:articleId" element={<ArticlesDetails />} /> 
          {/* //<Route path="/blog/:slug" element={<BlogPost />} /> */}
        </Routes>

        <BecomeContributor
          isOpen={isContributorModalOpen}
          onRequestClose={closeContributorModal}
        />

        <Footer onButtonClick={handleButtonClick}/>
      </div>
    </Router>
  );
}

export default App;
