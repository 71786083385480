import { useEffect } from 'react';

const MetaTags = ({ metaTags }) => {
  useEffect(() => {
    if (metaTags) {
      const head = document.querySelector('head');
      
      // Remove all existing title and meta tags
      const existingTitle = head.querySelector('title');
      if (existingTitle) {
        existingTitle.remove();
      }

      const existingMetaTags = head.querySelectorAll('meta');
      existingMetaTags.forEach(tag => tag.remove());

      // Insert the new metaTags (including title) as raw HTML
      head.insertAdjacentHTML('afterbegin', metaTags.trim());
    }
  }, [metaTags]);

  return null;
};

export default MetaTags;
